import 'iconify-icon'
import 'bootstrap'
import 'chartkick/chart.js'
import '@sonicgarden/auto-submit-form-element'
import '@sonicgarden/native-datepicker-element'
import { start } from '@sonicgarden/rails-ujs-compat'
import { startFlash } from '@/lib/flash'

start()
startFlash()
