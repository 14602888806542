import { openToast } from '@sonicgarden/bs-toast-element'
import { scriptJsonData } from './json'

type FlashData = {
  notice?: string
  alert?: string
}

export const startFlash = () => {
  document.addEventListener('DOMContentLoaded', () => {
    const flashData = scriptJsonData<FlashData>('json-flash')
    if (!flashData) return

    if (flashData.notice) {
      openToast(flashData.notice, { bgColor: 'success', delay: 5000 })
    }

    if (flashData.alert) {
      openToast(flashData.alert, { bgColor: 'danger', delay: 20_000 })
    }
  })
}
